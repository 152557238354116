import React, { Component } from 'react';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { resetHomeMessageSeen } from '../../../store/actions/userActions'


class VaccineStats extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    resetHomeMessages = () => {
        Object.entries(this.props.users).forEach(entry => {
            const id = entry[0];
            this.props.resetHomeMessageSeen(id);
        })
    }

    render() {
        const { users } = this.props;

        var vaccinatedCount = 0;
        var unvaccinatedCount = 0;
        Object.entries(users).forEach(entry => {
            const tech = entry[1];

            if (tech.role === 'tech') {
                if (tech.vaccineStatus === true) {
                    vaccinatedCount++
                } else {
                    unvaccinatedCount++;
                }
            }
        })

        return (
            <Card className="ManageCard">
                <Card.Header as="h5">Stats</Card.Header>
                <Card.Body>
                    <div className="card-content grey-text text-darken-3">
                        <Row>
                            <Col xs={12}>
                                <h5>Vaccinated Techs: {vaccinatedCount}</h5>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <h5>Unvaccinated/Undisclosed Techs: {unvaccinatedCount}</h5>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <h5>More project stats coming soon...</h5>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <Button
                                    style={{
                                        padding: '12px 20px',       // Maintains compact padding
                                        fontSize: '1rem',           // Standard font size
                                        lineHeight: '1.4',          // Standard line spacing
                                        whiteSpace: 'normal',       // Allows text wrapping if necessary
                                        textAlign: 'center',        // Centers the text within the button
                                        wordWrap: 'break-word',     // Prevents text overflow
                                        height: 'auto',             // Allows dynamic height based on content
                                        display: 'inline-block',    // Ensures the button fits snugly around the text
                                    }}
                                    href='https://firebasestorage.googleapis.com/v0/b/mmatablet-11c34.appspot.com/o/app%2FMMADoc.apk?alt=media&token=5ad98044-59c9-44ea-9521-645583511ffa'
                                >
                                    Tablet .apk Download
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
            </Card>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetHomeMessageSeen: (userID) => dispatch(resetHomeMessageSeen(userID)),
    }
}

export default connect(null, mapDispatchToProps)(VaccineStats);


/*
                    <Row>
                        <Button onClick={() => this.resetHomeMessages()}>Reset Home Message</Button>
                    </Row>
                    */